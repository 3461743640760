import { useNavigate } from "react-router-dom";

//   Customer List All (GET)
export const a1 =
  // "https://sunshineteam.in/loyalstring/api_ls_customerall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/GetAllCustomer";
  "https://raniwalajewellers.loyalstring.co.in/api/CustomerDetails/GetAllCustomer";

//   Customer List As per Customer Login Id (POST)
export const a2 =
  // "https://sunshineteam.in/loyalstring/api_ls_customerasperloginid_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/CustomerByLoginId";
  "https://raniwalajewellers.loyalstring.co.in/api/CustomerDetails/CustomerByLoginId";
//   Customer Details as per Customer id (POST)

export const a3 =
  // "https://sunshineteam.in/loyalstring/api_ls_customerasperuserid_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/GetCustomerById";
  "https://raniwalajewellers.loyalstring.co.in/api/CustomerDetails/GetCustomerById";

//   Add Customer (POST)
export const a4 =
  // "https://sunshineteam.in/loyalstring/api_ls_customer_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/AddCustomer";
  "https://raniwalajewellers.loyalstring.co.in/api/CustomerDetails/AddCustomer";

//   Update Customer with AnyField except Customer_id and Data (POST)
export const a5 =
  // "https://sunshineteam.in/loyalstring/api_ls_customer_update.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/UpdateCustomer";
  "https://raniwalajewellers.loyalstring.co.in/api/CustomerDetails/UpdateCustomer";
//   Add Staff (POST)
export const a6 =
  // "https://sunshineteam.in/loyalstring/api_ls_staff_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Staff/AddStaff";
  "https://raniwalajewellers.loyalstring.co.in/api/Staff/AddStaff";
//   Add Category (POST)
export const a7 =
  // "https://sunshineteam.in/loyalstring/api_ls_category_insert.php  ";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/InsertCategory";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/InsertCategory";
//   Add Product (POST)
export const a8 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/InsertProduct";
  // "https://sunshineteam.in/loyalstring/api_ls_product_insert.php";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/InsertProduct";
// "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/InsertBulkProduct";

//   Add Wishlist (POST)
export const a9 =
  "https://sunshineteam.in/loyalstring/api_ls_wishlist_insert.php";

//   Add Order (POST)
export const a10 =
  // "https://sunshineteam.in/loyalstring/api_ls_order_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Orders/InsertOrders";
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/InsertOrders";

export const a11 =
  "https://sunshineteam.in/loyalstring/api_ls_rating_insert.php";

// Add Rating (POST)

export const a12 =
  // "https://sunshineteam.in/loyalstring/api_ls_rates_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Orders/InsertRates";
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/InsertRates";
// Add Rates to Category (POST)

export const a13 =
  // "https://sunshineteam.in/loyalstring/api_ls_ratestoday_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Orders/ratestodaybycategory";
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/ratestodaybycategory";

// Get Todays Rate as per Category (POST)
export const a14 =
  "https://sunshineteam.in/loyalstring/api_ls_ratesalldays_data.php";
// Get All Rates as per Category (POST)

export const a15 = "https://sunshineteam.in/loyalstring/api_ls_rating_data.php";
// Get Rating as per Product id (POST)

export const a16 =
  // "https://sunshineteam.in/loyalstring/api_ls_productall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/GetfetchAllProduct";
  // "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/GetfetchAllProduct";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/GetuniqiueAllProduct";
// Get All Product Information (GET)

export const a17 =
  // "https://sunshineteam.in/loyalstring/api_ls_productbyproductid_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/fetchProductById";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/fetchProductById";
// Get Product Inforation as per Product Id (POST)

export const a18 =
  // "https://sunshineteam.in/loyalstring/api_ls_categoryall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/fetchAllCategory";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/fetchAllCategory";
// Get All Categories (GET)

export const a19 =
  // "https://sunshineteam.in/loyalstring/api_ls_ratestodayallcategories_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Orders/fetchTodayRates";
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/fetchTodayRates";
// Get All Rates of today of all categories (GET)

export const a20 =
  // "https://sunshineteam.in/loyalstring/api_ls_producttypeall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/fetchAllProductType";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/fetchAllProductType";
// Get All ProductTypes (GET)

export const a21 =
  // "https://sunshineteam.in/loyalstring/api_ls_producttype_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/InsertProductType";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/InsertProductType";
// Insert ProductType (POST)

export const a22 =
  // "https://sunshineteam.in/loyalstring/api_ls_purityall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/fetchAllPurity";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/fetchAllPurity";
// Get All Purity data (GET)

export const a23 =
  // "https://sunshineteam.in/loyalstring/api_ls_purity_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/InsertPurity";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/InsertPurity";
// Insert Purity data (POST)

export const a24 =
  // "https://sunshineteam.in/loyalstring/api_ls_product_update.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/UpdateProduct";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/UpdateProduct";
// Update Product data (POST)

export const a25 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/UpdatePassword";
  "https://raniwalajewellers.loyalstring.co.in/api/CustomerDetails/UpdatePassword";
// Update Password

export const a26 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/UpdateImage";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/UpdateImage";
// Update Images

export const a27 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/AddPartyMaster";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/AddPartyMaster";
// Add Supplier Details

export const a28 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/GetAllPartyMaster";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/GetAllPartyMaster";
// Get Supplier Details

export const a29 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/AddBoxMaster";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/AddBoxMaster";
// Add Box Details

export const a30 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/GetAllBoxMaster";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/GetAllBoxMaster";
// Get Box Details

export const a31 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/BulkUpdateProduct";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/BulkUpdateProduct";
// "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/BulkEditProduct";
// Update Bulk Products

export const a32 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/AddCollection";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/AddCollection";
// Insert Collection

export const a33 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/GetAllCollection";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/GetAllCollection";
// Get Collection

export const a34 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/UpdateCollection";
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/UpdateCollection";
// Update Collection

export const a35 =
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/UpdateCategory";
// Update Category

export const a36 =
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/UpdateProductType";
// Update ProductType

export const a37 =
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/UpdatePurity";
// Update ProductType

export const a38 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/fetchAllOrders";
// fetch all order

export const a39 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/UpdateOrders";
// Update orders

export const a40 =
  // "https://sunshineteam.in/loyalstring/api_ls_customer_update.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/UpdateCustomer";
  "https://raniwalajewellers.loyalstring.co.in/api/CustomerDetails/UpdateCustomers";
// Update Customer all fields

export const a41 =
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/fetchAllProduct";
// Get All Products

export const a42 =
  "https://raniwalajewellers.loyalstring.co.in/api/CustomerDetails/GetOtp";
// Get All Products

export const a43 =
  "https://raniwalajewellers.loyalstring.co.in/api/Device/GetAllRFID";
// Get All Barcode and Tid

export const a44 =
  "https://raniwalajewellers.loyalstring.co.in/api/Device/GetAllProduct";
// Get All Data from external

export const a45 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/DownloadPDF";
// Download pdf with Img

export const a46 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/PrintPDF";
//  Download pdf without Img

export const a47 =
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/deleteProduct";
// Delete Product with itemcode

export const a48 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/CreateOrder";
// Create Order Multiple

export const a49 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/CreateOrderItem";
// Create Order Items Multiple

export const a50 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/AllOrderItemsByOrderId";
// Get Order Items By Order id

export const a51 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/DownloadInvoice";
// Download Invoice

export const a52 =
  // "https://raniwalajewellers.loyalstring.co.in/api/Orders/AllOrderItemsByOrderId";
  "https://raniwalajewellers.loyalstring.co.in/api/orders/Getfetchordersbyid";
// Get All Order Items

export const a53 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/CreateURDPurchase";
// Add Purchase Items

export const a54 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/GetAllURDPurchase";
// Get All Purchase Items

export const a55 =
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/InsertUnlabledProducts";
// Add Unlabelled Items

export const a56 =
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/GetfetchAllUnLabeledProducts";
// Get All Unlabelled Items

export const a57 =
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/BulkUpdateUnLabeledProduct";
// Update Unlabelled Items

export const a58 =
  "https://raniwalajewellers.loyalstring.co.in/api/Staff/AddSalesTeam";
// Add New Employees

export const a59 =
  "https://raniwalajewellers.loyalstring.co.in/api/Staff/GetAllSaleTeam";
// Get All Employee

export const a60 =
  "https://raniwalajewellers.loyalstring.co.in/api/Staff/UpdateSalesTeam";
// Edit Employee

export const a61 =
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/GetActiveAllUnLabeledProducts";
// Get Active Unlabel Products

export const a62 =
  "https://raniwalajewellers.loyalstring.co.in/api/Device/Login";
// Admin Panel Token Login

export const a63 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/CreateCreditNote";
// Apply Credit Note

export const a64 =
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/UpdatePartyMaster";
// Update Supplier

export const a65 =
  "https://raniwalajewellers.loyalstring.co.in/api/RDPurchase/CreateRDPurchase";
// Add Purchase Main Box

export const a66 =
  "https://raniwalajewellers.loyalstring.co.in/api/RDPurchase/CreateRDPurchaseItem";
// Add Purchase Items

export const a67 =
  "https://raniwalajewellers.loyalstring.co.in/api/RDPurchase/GetAllRDPurchase";
// Get All Rd Purchase

export const a68 =
  "https://raniwalajewellers.loyalstring.co.in/api/StyleMaster/GetAllStones";
// Get All Stones

export const a69 =
  "https://raniwalajewellers.loyalstring.co.in/api/StyleMaster/AddStone";
// Add New Stone

export const a70 =
  "https://raniwalajewellers.loyalstring.co.in/api/StyleMaster/UpdateStone";
// Update Stone

export const a71 =
  "https://raniwalajewellers.loyalstring.co.in/api/StyleMaster/GetAllSkuList";
// Get ALL Sku

export const a72 =
  "https://raniwalajewellers.loyalstring.co.in/api/StyleMaster/AddSKUMaster";
// Add Sku

export const a73 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/GetAllPaymentMode";
// Get All Payments

export const a74 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/CreatePaymentMode";
// Add New Payment

export const a75 =
  "https://raniwalajewellers.loyalstring.co.in/api/RDPurchase/PartyLedgerBySupplierId";
// Get PartyLedger By SupplierId

export const a76 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/UpdateOrder";
// Update Customer Order Main Box

export const a77 =
  "https://raniwalajewellers.loyalstring.co.in/api/RDPurchase/UpdateRDPurchase";
// Update RD Purchase Main Box

export const a78 =
  "https://raniwalajewellers.loyalstring.co.in/api/RDPurchase/UpdateRDPurchaseBalance";
// Update RD Purchase Main Box Balance

export const a79 =
  "https://raniwalajewellers.loyalstring.co.in/api/ProductMaster/UpdateBoxMaster";
// Update Box Master

export const a80 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/CreateDebitNote";
// Create Debit Note

export const a81 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/updateOrderItem";
// Update Order Items

export const a82 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/LabeledStockReport";
// Get Labelled Stock Report

export const a83 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/ExchangeOrderItem";
// Return Order Items

export const a84 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/GetAllCreditNote";
// Get All Credit Note

export const a85 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/GetAllDebitNote";
// Get All Debit Note

export const a86 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/UpdateOrder";
// Update Order Main Box

export const a87 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/GetAllOrderItems";
// Get All Order Items

export const a88 =
  "https://raniwalajewellers.loyalstring.co.in/api/RDPurchase/GetAllRDPurchaseItem";
// Get All RD Purchase Items

export const a89 =
  "https://raniwalajewellers.loyalstring.co.in/api/StyleMaster/UpdateSKUMaster";
// Update Sku

export const a90 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/UploadExcel";
// Upload Excel

export const a91 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/DeletePaymentMode";
// Delete Payment

export const a92 =
  "https://raniwalajewellers.loyalstring.co.in/api/Orders/ReturnAdvancePayment";
// Return Advance to customer

// export const s1 = `http://localhost:3000/demo/Images/`;
// export const s1 = `https://mkgharejewellers.com/demo/Images/`;
export const s1 = `https://raniwalajewellers.loyalstring.co.in/images`;
// export const s1 = `https://goldkarizma.com/demo/Images/`;

//For Local Images
// export const navigates = useNavigate();
// export const s2 = `http://localhost:3000/contactdetails`;

export const s2 = `https://mkgharejewellers.com/contactdetails`;
// export const s2 = `https://mgharejewellers.com/demo/contactdetails`;
// export const s2 = `https://goldkarizma.com/demo/contactdetails`;
// export const s3 = "https://product123.blob.core.windows.net/images";

export const s3 =
  // "https://product123.blob.core.windows.net/images";
  "https://jewellerywebapplications.blob.core.windows.net/images";
// "https://raniwalajewellers.loyalstring.co.in/api/RDPurchase/GetAllRDPurchase";

export const paymentApi = "http://localhost:5000";
// export const paymentApi = "https://goldkarizma.com";
// export const paymentApi = "https://goldkarizma.com/nodjs";
